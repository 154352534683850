<template>
  <v-card class="card-shadow border-radius-xl mt-6" id="change">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Change Password</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Current password</label
          >
            <v-text-field
            dense
            single-line
            height="36" 
            outlined
            color="rgba(0,0,0,.6)"
            light
         class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
            v-model="current_password"
            :rules="[() => !!current_password || 'This field is required']"
            :append-icon="showCurrentPassword ? 'fa-eye-slash' : 'fa-eye'"
            :type="showCurrentPassword ? 'text' : 'password'"
            placeholder="*********"
            required
            @click:append="showCurrentPassword = !showCurrentPassword"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >New password</label
          >
          <v-text-field 
            dense
            single-line
            height="36"      
            outlined
            color="rgba(0,0,0,.6)"
            light
              class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
            v-model="password"
            :rules="[() => !!password || 'This field is required']"
            :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
            :type="showPassword ? 'text' : 'password'"
            placeholder="*********"
            required
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Confirm new password</label
          >
          <v-text-field 
            dense
            single-line
            height="36" 
            outlined
            color="rgba(0,0,0,.6)"
            light
          class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
             "
            v-model="confirm_password"
            :rules="[() => !!confirm_password  && confirm_password == password || 'Passwords must match']"
            :append-icon="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
            :type="showConfirmPassword ? 'text' : 'password'"
            placeholder="*********"
            required
            @click:append="showConfirmPassword = !showConfirmPassword"
          >
          </v-text-field>
        </v-col>
      </v-row>


      <div class="d-sm-flex mt-4">
        <v-btn
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          "
          small
          @click="updatePassword"
        >
          Update password
        </v-btn>
      </div>
      </v-form>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "change-password",
  data() {
    return {
      current_password: "",
      password: "",
      confirm_password: "",
      showCurrentPassword: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
   methods: {
    updatePassword: function () {
      if (this.$refs.form.validate()) {
          this.$store.dispatch('updatePassword', { password: this.password, current_password: this.current_password  })
            .then( response => {
              this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
            })
            .catch(err => {
              this.$store.commit('showMessage',{ content: err.response.data.msg, color: 'warning' })

            })
      }else {
         this.$store.commit('showMessage',{ content: "Kindly fill in both fields", color: 'warning' })
      }
    },
    
  }
};
</script>
