<template>

     <v-dialog
        width="800px"
        v-model="dialog"
        persistent
    >
    
    <v-card
      class="mx-auto"
    >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title> 
        
          Edit Invoice
         <v-spacer>
                       </v-spacer>
                       <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
             </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
     
        <v-form
                  ref="invoiceForm"
                  lazy-validation>
                   <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">  
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Comment</label>  

                    <v-textarea
                      auto-grow
                      rows=1
                      outlined
                      v-model="invoice.comment"
                  
                        color="rgba(0,0,0,.6)"

                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                    ></v-textarea>  
                        </v-col>
                    </v-row>
                    
                  </v-form>
                

        </v-card-text>

     <v-card-actions style="margin:auto;">
          <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "

                @click="editInvoice()"
              >
                <v-icon size="12" class="pr-4">fa fa-check</v-icon>
                Save
              </v-btn>
    </v-card-actions>
  
  </v-card>

</v-dialog>
  
</template>

<script>
  export default {
    name: 'EditInvoiceCard',
    props: {
        invoice: {
          default: null,
          required: true
      },
        dialog: {
          type: Boolean,
          default: false
      }
    },

  methods: {
 
      editInvoice(){
          if(this.$refs.invoiceForm.validate()){
                        this.$store.dispatch('editInvoice',{invoice: this.invoice})
                        .then( async (response) => {
                                this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                                this.$emit('closeDialog')
                        }  )
                        .catch((err) => {
                           this.$store.commit('showMessage',{ content: err.response.data.msg, color: 'warning' })

                        })    

            }else{
                this.$store.commit('showMessage',{ content: "Please fill in the missing fields.", color: 'warning' })


            }
      

           
      },
    

  }
  }
</script>
