<template>
<v-dialog
        width="800px"
        v-model="dialog"
        persistent
    >
    
    <v-card
      class="mx-auto"
    >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title> 
        
        {{ template.name }}
          <v-spacer> </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
    </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
                   <v-form
                          ref="templateForm"
                          lazy-validation>
                        <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">
                          <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Template Name</label>
                        <v-text-field
                          
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                          v-model="template.name"
                          dense
                            single-line
                                height="36"
                        ></v-text-field>
                          </v-col>
                        </v-row>
                      <v-row class="pb-0 pt-0" v-if='permissions == "Admin"'>
                        <v-col class="pb-0 pt-0">
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Users</label>
                        <v-select
                          :items="users"
                           v-model="template.users"
                           item-text="name"
                           outlined
                           multiple
                           return-object
                           color="rgba(0,0,0,.6)"
                           class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-2
                              mb-0
                            "
                                single-line
                                height="36"
                        ></v-select>
                        </v-col>
                      </v-row>
                    <v-row class = "pb-0 pt-0">
                        <v-col class="pb-0 pt-0">
                     <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Markets</label>
                    <v-select
                      :items="markets"
                      v-model="template.markets"
                      item-text="code"
                      item-value="id"
                      outlined
                      multiple
                      return-object
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-2
                        mb-0
                      "
                      single-line
                      height="36"
                      color="rgba(0,0,0,.6)"
                      :rules="[() => !!template.markets || 'Market is required']"
                      required
                    >
                            <template slot="selection" slot-scope="data">
                                                <div class="pl-2 pr-2"> {{ data.item.market_name }}</div>
                                                 <v-img
                                                :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                max-width="25px"
                                                class="font-size-input
                                                  placeholder-lighter
                                                  "
                                            ></v-img>                            
                                          </template>
                                      <template slot="item" slot-scope="data" >
                                                <div class="pr-2 pl-2"> {{ data.item.market_name }} </div>
                                                    <v-img
                                                        :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                        max-width="25px"
                                                        class="  font-size-input
                                                          placeholder-lighter
                                                          mt-2"
                                                    ></v-img>                                
                                              </template>
                    
                    
                    </v-select>
                     </v-col>
                   </v-row>
                   <v-row>
              
                    <v-col>
                        <v-autocomplete
                          v-model="chosenElement"
                          :items="elements"
                          outlined
                          dense
                          item-value="element"
                          item-text="element"
                          placeholder="Template Element"
                          color="rgba(0,0,0,.6)"
                          lights
                          class="
                              font-size-input
                              placeholder-lighter
                              input-style 
                              mt-2
                          "
                          return-object
                        ></v-autocomplete>
                          </v-col>
                          <v-col cols="1">
                          <v-icon class="mt-4" @click="addChosenElement(true)">fa fa-plus</v-icon>
                        </v-col>
                      
                        </v-row>
                         <v-list shaped>
                        <v-subheader>Template Preview</v-subheader>
                        <v-list-item-group
                          color="primary"
                        >
                       <draggable
                          v-model="templateElements"
                        >
                        <v-list-item
                          v-for="(item, i) in templateElements"
                          :key="item.id"
                        >
                          <v-list-item-content v-if='item.templateElement'>
                            <p :class="'ml-'+item.templateElement.spacing" :style="{'color': item.templateElement.color_code + '!important'}">{{ item.templateElement.element }}</p>
                          </v-list-item-content>
                          <v-row>
                            <v-col cols="4"><v-textarea label="Headline" auto-grow rows=1  v-model=item.default_headline> </v-textarea></v-col>
                           <v-col><v-textarea label="Instructions" auto-grow rows=1  v-model=item.default_instructions> </v-textarea></v-col>
                            <v-col cols="1"> <v-icon color="red" @click="deleteElement(i,true)">fa fa-minus</v-icon> </v-col>

                            </v-row>
                        </v-list-item>
                       </draggable>
               </v-list-item-group>
             </v-list>
           </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "

                @click="editTemplate()"
              >
                <v-icon size="12" class="pr-4">fa fa-check</v-icon>
                Save
              </v-btn>
    </v-card-actions>

  </v-card>

</v-dialog>
  
</template>

<script>
import draggable from 'vuedraggable';

  export default {
    name: 'EditTemplateCard',
    components:{
      draggable
    },
    props: {
      template: {
          default: {},
          required: true
      },
      showCloseButton: {
        default: true
      },
      dialog: {
          type: Boolean,
          default: false
      }
    },
    data: function () {
    return {
      permissions: this.$store.state.permissions,
      templateElements: [],
      chosenElement: {},
      elements: [],
      markets: [],
      users: [],
    }
  },
    watch:{


    },
    created: function (){
       if(this.permissions == 'Admin'){
        this.$store.dispatch('getUsers')
            .then( (response) => {
                  this.users = response.data.data
            }  )
            .catch((e) => {
               this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            })  
    }

        this.$store.dispatch('getMarkets')
        .then( async (response) => {

              this.markets = response
        }  )
        .catch(() => {
     
          this.$store.commit('showMessage',{ content: "Error while loading markets data...", color: 'warning' })

        })  

      this.$store.dispatch('getTemplateElements')
      .then( async (response) => {
        this.elements = response.data.data
      })
      .catch(() => {
        this.$store.commit('showMessage',{ content: "Error while getting template elements", color: 'warning' })
      })

      this.$store.dispatch('getTemplateDetails', {templateId: this.template.id})
       .then( (response) => {
            this.templateElements = response.data.data
             console.log(this.template)
       })
       .catch((e) => {
            this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
       })  
    },
  methods: {
      addChosenElement(){
        if("element" in this.chosenElement){
           this.templateElements.push({templateElement: {id: this.chosenElement.id ,spacing:this.chosenElement.spacing, element: this.chosenElement.element, color_code: this.chosenElement.color_code}, default_instructions: "", default_headline: ""})
           this.chosenElement = {}
        }else{
            this.$store.commit('showMessage',{ content: "Please select a template element first!", color: 'warning' })
        }
         
      },
      deleteElement(index){
         this.templateElements.splice(index,1)
   
      },
      editTemplate(){
        if(this.$refs.templateForm.validate()){
            this.template.elements = this.templateElements
            this.$store.dispatch('editTemplateDetails', {template: this.template})
            .then( (response) => {
                  this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                  this.$emit('closeDialog')
            })
            .catch((e) => {
               this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 
        }else{
             this.$store.commit('showMessage',{ content: "Please fill in the missing fields.", color: 'warning' })
        }
        

    },
    }
  }
</script>
