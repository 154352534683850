var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl mt-6"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table",attrs:{"search":_vm.search,"items-per-page":50,"sort-by":['name'],"sort-desc":[false],"headers":_vm.headers,"items":_vm.freelancers,"mobile-breakpoint":"0","hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"90"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"\n                    input-style\n                    font-size-input\n                    text-light-input\n                    placeholder-light\n                    input-icon\n                    mt-4\n                  ",attrs:{"dense":"","flat":"","filled":"","solo":"","height":"36","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v("fas fa-search")])],1)],2)],1)],1)],1)]},proxy:true},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled == true)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-2 border-success",attrs:{"ripple":false,"icon":"","outlined":"","rounded":"","width":"25px","height":"25px"}},[_c('v-icon',{staticClass:"text-success",attrs:{"size":"8"}},[_vm._v(" fas fa-check ")])],1),_c('span',{staticClass:"text-body text-xs"},[_vm._v("Enabled")])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-2 border-danger",attrs:{"ripple":false,"icon":"","outlined":"","rounded":"","width":"25px","height":"25px"}},[_c('v-icon',{staticClass:"text-danger",attrs:{"size":"8"}},[_vm._v(" fas fa-times ")])],1),_c('span',{staticClass:"text-body text-xs"},[_vm._v("Disabled")])],1)]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [(item.available == true)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-2 border-success",attrs:{"ripple":false,"icon":"","outlined":"","rounded":"","width":"25px","height":"25px"}},[_c('v-icon',{staticClass:"text-success",attrs:{"size":"8"}},[_vm._v(" fas fa-check ")])],1),_c('span',{staticClass:"text-body text-xs"},[_vm._v("Available")])],1):_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-2 border-danger",attrs:{"ripple":false,"icon":"","outlined":"","rounded":"","width":"25px","height":"25px"}},[_c('v-icon',{staticClass:"text-danger",attrs:{"size":"8"}},[_vm._v(" fas fa-times ")])],1),_c('span',{staticClass:"text-body text-xs"},[_vm._v("Not Available")])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" €"+_vm._s(item.price)+" ")]}},{key:"item.invoices",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({path: '/invoices' , query: {freelancerId: item.id}})}}},[_c('v-icon',[_vm._v("fa fa-file-invoice")])],1)]}},{key:"item.markets",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_vm._l((item.markets),function(market){return [(market.flag != null)?_c('img',{key:market.id,staticClass:"mr-2",staticStyle:{"max-width":"35px"},attrs:{"src":require('@/assets/img/icons/flags/'+market.flag+'.svg')}}):_vm._e()]})],2)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.openEditFreelancer(item)}}},[_vm._v(" fa fa-edit ")])]}}])})],1)],1),(_vm.showEditFreelancerDialog)?_c('edit-freelancer-card',{attrs:{"dialog":_vm.showEditFreelancerDialog,"freelancer":_vm.freelancerData},on:{"closeDialog":function($event){_vm.showEditFreelancerDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }