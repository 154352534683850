<template>
  <v-container>
      <v-row>
       <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="filter.dateRange"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                          <template v-slot:activator="{ on, attrs }">
                        
                              <v-text-field
                                v-model="dateRangeText"
                                label="Date range"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                @click:clear="() => { filter.dateRange = []; updateData()}"
                              ></v-text-field>
                          </template>
                            <v-date-picker
                              v-model="filter.dateRange"
                              range                              
                            >
                                <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="() => { $refs.menu.save(filter.dateRange); updateData()}"
                            >
                              OK
                            </v-btn>
                            
                            </v-date-picker>
                        </v-menu>
      </v-row>
      <v-row>

    <v-col  v-for="market of delivered" :key=market.marketId cols="3">
        <v-card >
                <div
                  class="
                    overflow-hidden
                    position-relative
                    border-radius-lg
                    h-100
                  "
                >
                  <span class="mask bg-gradient-secondary opacity-8"></span>
                  <div class="px-4 py-4 position-relative z-index-1 h-100">
                    <h6 class="text-white text-h6 font-weight-bolder mb-4">
                    </h6>
                        <v-img
                                    v-if="market.market.flag != null"
                                    :src="require('@/assets/img/icons/flags/'+market.market.flag+'.svg')"
                                    max-width="35px"
                                    class="  font-size-input
                                      placeholder-lighter
                                      mt-2"
                                ></v-img> <br>
                    No Of Orders: {{ market.noOfOrders }}<br>
                    Words Delivered: {{ market.words_delivered }}<br>
                    Total Cost: €{{ roundTo(market.total_cost,2)}}<br>

              
                  </div>
                </div>
              </v-card>
              </v-col>
      </v-row>
      <v-row>
          Date Delivered:
    <v-col v-for="status of wordsDelivered" :key=status.orderStatusId>

                 <v-card  >
                <div
                  class="
                    overflow-hidden
                    position-relative
                    border-radius-lg
                    h-100
                  "
                >
                  <span class="mask bg-gradient-secondary opacity-8"></span>
                  <div class="px-4 py-4 position-relative z-index-1 h-100">
                    <h6 class="text-white text-h6 font-weight-bolder mb-4">
                    </h6>
                    Status: {{ status.orderStatus.status }}<br>
                    Words: {{ status.words }}<br>
                    No Of Orders: {{ status.noOfOrders }}<br>
                    Total Cost: €{{ roundTo(status.total_cost,2)}}<br>

               
                  </div>
                </div>
              </v-card>
              </v-col>
      </v-row>
      <v-row>
          Date Ordered:
    <v-col v-for="status of wordsOrdered" :key=status.orderStatusId>

                 <v-card >
                <div
                  class="
                    overflow-hidden
                    position-relative
                    border-radius-lg
                    h-100
                  "
                >
                  <span class="mask bg-gradient-secondary opacity-8"></span>
                  <div class="px-4 py-4 position-relative z-index-1 h-100">
                    <h6 class="text-white text-h6 font-weight-bolder mb-4">
                    </h6>
                    Status: {{ status.orderStatus.status }}<br>
                    Words: {{ status.words }}<br>
                     No Of Orders: {{ status.noOfOrders }}<br>

               
                  </div>
                </div>
              </v-card>
              </v-col>
              </v-row>
  </v-container>

</template>

<script>
const queryString = require('query-string');

export default {

  data: function () {
    return {
      menu: false,
      snackbarMsg: '',
      snackbar: false,
      filter: {
        status: '',
        language: '',
        orderId: '',
        month: '',
        delivery_date: '',      
        dateRange:[new Date(new Date().getFullYear() , new Date().getMonth() - 1, 1).toLocaleDateString('fr-CA'),new Date(new Date().getFullYear() , new Date().getMonth() , 0).toLocaleDateString('fr-CA')]
      },
      delivered: [],
      rejected: [],
      wordsDelivered: [],
      wordsOrdered: []
    }
  },
computed: {
    dateRangeText () {
        return this.filter.dateRange.join(' - ')
      },

  },
  created: async function (){

         this.$store.dispatch('getAnalyticsMarket', {query: queryString.stringify(this.filter)})
            .then( (response) => {

                  this.delivered = response.data.data.delivered
                  this.rejected = response.data.data.rejected
                    console.log(response.data)
            })
            .catch((e) => {
                this.snackbarMsg = e.response.data.msg
              this.snackbar= true
              console.log(e)
            })  


            this.$store.dispatch('getAnalyticsWords', {query: queryString.stringify(this.filter)})
            .then( (response) => {

                  this.wordsDelivered = response.data.data.dateDelivered
                  this.wordsOrdered = response.data.data.dateOrdered
            })
            .catch((e) => {
                this.snackbarMsg = e.response.data.msg
              this.snackbar= true
              console.log(e)
            }) 

  },
  methods: {
      updateData(){
            this.$store.dispatch('getAnalyticsMarket', {query: queryString.stringify(this.filter)})
            .then( (response) => {

                  this.delivered = response.data.data.delivered
                  this.rejected = response.data.data.rejected
                    console.log(response.data)
            }  )
            .catch((e) => {
                this.snackbarMsg = e.response.data.msg
              this.snackbar= true
              console.log(e)
            })  
                 this.$store.dispatch('getAnalyticsWords', {query: queryString.stringify(this.filter)})
            .then( (response) => {

                  this.wordsDelivered = response.data.data.dateDelivered
                  this.wordsOrdered = response.data.data.dateOrdered
            })
            .catch((e) => {
                this.snackbarMsg = e.response.data.msg
              this.snackbar= true
              console.log(e)
            }) 
      },
    roundTo(n, digits) {
        var negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        var multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        n = (Math.round(n) / multiplicator).toFixed(digits);
        if (negative) {
            n = (n * -1).toFixed(digits);
        }
        return n;
    }
      
  }

 
}
</script>