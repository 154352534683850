<template>
 <div>
    <v-container fluid class="py-6">
      <div class="d-sm-flex justify-between">
        <v-btn
          @click="showNewTemplateDialog = true"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-sm-0 mb-2
          "
          color="#5e72e4"
          small
          >New template</v-btn
        >
        
      </div>

      <v-row>
        <v-col cols="11">
          <v-card class="card-shadow border-radius-xl">
            <template-list :templates=templates @duplicateTemplate="rightClickDuplicate"></template-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
            <new-template-card :dialog=showNewTemplateDialog v-if="showNewTemplateDialog" :template="newTemplateObject" @createdTemplate="createdTemplate"  @closeDialog="closeNewTemplateDialog" />

  </div>

</template>

<script>
import draggable from 'vuedraggable';
import TemplateList from "./TemplateList";
import NewTemplateCard from "./Components/NewTemplateCard.vue"

export default {
     components: {
            draggable,
            TemplateList,
            NewTemplateCard
   },

  data: function () {
    return {
      permissions: localStorage.getItem('permission'),
      userId: localStorage.getItem('id'),
      markets: [],
      color: 'general',
      search: "",
      templateCardDialog: false,
      templateData: {},
      templates: [],
      filter: {enabled: "true"},
      elements: [],
      chosenElement: {},
      users: [],
      duplicateTemplateObject: {name: "", elements: [], users: []},
      newTemplateObject: {name: "", elements: [], users: []},
      showNewTemplateDialog:false,
    }
  },
  created: function (){

    this.$store.dispatch('getTemplates')
      .then( (response) => {
            this.templates = response.data.data
      })
      .catch((e) => {
 
            this.$store.commit('showMessage',{ content: e.data.msg, color: 'warning' })

    })   

  
  },

  methods: {
    
    refreshTemplates(){
       this.$store
       .dispatch("getTemplates")
       .then(async (response) => {
           this.templates = response.data.data
       })
       
    },
    viewTemplateDetails(item){
        this.template = item
           this.$store.dispatch('getTemplateDetails', {templateId: item.id})
            .then( (response) => {
                  this.templateData = response.data.data
                  this.$store.state.showTemplateCardDialog = true
                  this.templateCardDialog = true
            }  )
            .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            })   
    },

    openEditTemplateDetails(item){
        this.editTemplate.name = item.name
        this.editTemplate.id = item.id 
        this.editTemplate.markets = item.markets

        this.$store.dispatch('getTemplateDetails', {templateId: item.id})
            .then( (response) => {
                this.editTemplate.users = response.data.users
                console.log(response.data.users)
                this.editTemplate.elements = response.data.data
                this.editTemplateCardDialog = true
            }  )
            .catch((e) => {
                 this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            })   


        
    },
    editTemplateDetails(){
      console.log(this.editTemplate.elements)
          this.$store.dispatch('editTemplateDetails', {template: this.editTemplate})
            .then( (response) => {
                this.$store.commit('showMessage',{ content: response.data.msg, color: 'warning' })

                this.editTemplateCardDialog = false

            }  )
            .catch((e) => {
              console.log(e)
              this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            }) 

    },
    disableTemplate(templateId){
           this.$store.dispatch('disableTemplateById', {templateId: templateId})
            .then( (response) => {
                this.$store.commit('showMessage',{ content: response.data.msg, color: 'warning' })
                  
            }  )
            .catch((e) => {
                this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 

    },
    addChosenElement(){
      if("element" in this.chosenElement){
                this.editTemplate.elements.push({templateElement: {id: this.chosenElement.id ,element: this.chosenElement.element,color_code: this.chosenElement.color_code} , default_instructions: ""})
      }else{
           this.$store.commit('showMessage',{ content: "Please select a template element first!", color: 'warning' })
      }
      },
      deleteElement(index){

        this.editTemplate.elements.splice(index,1)
      },    
    rightClickDuplicate(obj){
        obj.event.preventDefault();
          this.$store.dispatch('getTemplateDetails', {templateId: obj.item.item.id})
              .then( (response) => {
                  this.showNewTemplateDialog = true;
                  this.duplicateTemplateObject.elements = response.data.data
                  this.newTemplateObject = this.duplicateTemplateObject
                  console.log(this.newTemplateObject)
              }  )
              .catch((e) => {
                  console.log(e)
                  this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

              })   


      },
    createdTemplate(template){
        console.log(template)
        template.enabled = true
        this.templates.push(template)
        this.showNewTemplateDialog = false
        //send event to template list or add to templates 
    },
    closeNewTemplateDialog(){
        this.showNewTemplateDialog = false 
        this.newTemplateObject = {name: "",elements:[]}
    }
  }
}
</script>
