<template>
  <div class="mt-12">
  <v-container fill-width fluid style="max-width:95% !important;">
     
    <v-row>
            <v-col cols="4">
                  <order-card style="height:900px !important; overflow-y: scroll"  :order=order />

        </v-col>

        <v-col cols="8">
           <v-card

              style="overflow-y: scroll; height:900px;"
          >
  
            <vue-editor id="editor" :disabled="disableEdit" v-model="content" />
        </v-card>
        </v-col>

        </v-row>
          <v-row v-if='permissions == "Admin"'>
              <v-col class="text-right">
            
                 <v-switch
                      class="switch"
                      color="#3a416ff2"
                      v-model="autoSave"
                      label= "Auto Save"                      
                          ></v-switch>
            </v-col>
            <v-col class="text-right">
                <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "
                @click="save(orderId,true)"
              >
                <v-icon size="12" class="pr-4">fa fa-check</v-icon>
                Finish
              </v-btn>
            </v-col>
      </v-row>
  </v-container>
  </div>
</template>

<style>
canvas {
  width: 100% !important;
  height: 600px !important;
}
tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
#orderForm input{
    color: black !important;
}
#orderForm textarea{
    color: black !important;
}
/* #editor{
    height: 800px !important; 
} */
</style>

<script>
//USE TIPTAP AS Text editor
// import { VueEditor } from "vue2-editor";
  import { VueEditor } from 'vue2-quill-editor'
import OrderCard from "./Components/PreviewOrderCard.vue"
export default {
   components: { VueEditor, OrderCard },

  data: function() {
    return {
      type: "",
      title: "",
      snackbar: false,
      snackbarMsg: "",
      content: "",
      orderId: "",
      autoSave: false,
      refreshId: "",
      order: {},
      markets: [],
      permissions: localStorage.getItem('permission'),
      disableEdit: false
    };
  },
  watch: {
    autoSave(){
            if(this.autoSave){
                  var self = this
                  this.refreshId = setInterval(function(){     
                      if(self.domain != ""){
                        self.save(self.orderId)
                      }
            
                      }, 10000);
              }else{
                    clearInterval(this.refreshId);

              }
    } 
  },
  created: function() {
    this.$store.dispatch('getMarkets')
        .then( async (response) => {

              this.markets = response
        }  )
        .catch(() => {
     
          this.$store.commit('showMessage',{ content: "Error while loading markets data...", color: 'warning' })

        })    
     
    

        if(this.permissions == 'Countrymanager' || this.permissions == 'Freelancer'){
                  this.autoSave = false
                  this.disableEdit = true

        }else{
                  this.autoSave = true
        }
        
        this.orderId = this.$route.query.orderId

        if(typeof this.orderId != "undefined"){
             //Get Order Details
            this.$store.dispatch('getOrderWithId', {orderId: this.orderId})
            .then( (response) => {
                this.content = response.data.data.html
                this.order = response.data.data
                console.log(this.order)
                    if(this.order.status == 'Done' && this.permissions != 'Admin')
                       this.disableEdit = true
            })
            .catch((e) => {
                 this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            })   
         
        } else{

            //Show error
             console.log("Error")

        }
 
 
    },
    methods: {
    
        save (orderId,showSnackbar = false){
          if(!this.disableEdit){
               var content = this.content
                this.$store
                    .dispatch("saveContent", {orderId: orderId,content: content })
                    .then(async (response) => {
                      
                      if(showSnackbar)
                         this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })

                    }).catch( (e) => {
                                      this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

                    })          
            }
               
        },
     

    },
     destroyed(){
        clearInterval(this.refreshId);

    } 


  }

</script>
