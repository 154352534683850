<template>
  <v-container fluid class="py-6">
  
    <v-row>
      <v-col lg="9">
        <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <!-- <v-avatar width="74" height="74" class="shadow border-radius-lg">
                <img
                  src="@/assets/img/bruce-mars.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar> -->
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  {{ name }}
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  <template v-if='$store.state.permissions == "Countrymanager"'> User </template><template v-else>{{ $store.state.permissions }}</template>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <basic-info></basic-info>
        <change-password></change-password>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BasicInfo from "./Widgets/BasicInfo.vue";
import ChangePassword from "./Widgets/ChangePassword.vue";

export default {
  name: "Settings",
  components: {
    BasicInfo,
    ChangePassword,

  },
  data() {
    return {
      switche: true,
      name: localStorage.getItem("name")
    };
  },
};
</script>
