<template>

  <v-dialog
        width="800px"
        v-model="dialog"
        persistent
    >
    
    <v-card
      class="mx-auto"
    >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title> 
        
          New Freelancer
         <v-spacer> </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
             </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
     
        <v-form
                  v-if="showFreelancerForm"
                  ref="freelancerForm"
                  lazy-validation>
                     <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">
                          <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Name</label>
                         <v-text-field
                          
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                          v-model="freelancer.name"
                          dense
                            single-line
                                height="36"
                                 :rules="[() => !!freelancer.name || 'Name is required']"
                      required
                        ></v-text-field>
                        </v-col>
                     </v-row>            
      
                     <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">  
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Email</label>          
                          <v-text-field
                          
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                          v-model="freelancer.email"
                          dense
                            single-line
                                height="36"
                                 :rules="[() => !!freelancer.email || 'Email is required']"
                            required
                        ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0"> 
                           <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Rate (€)/Word </label>   
                     <v-text-field
                      outlined
                      
                      color="rgba(0,0,0,.6)"
                      light
                      class="
                          font-size-input
                          placeholder-lighter
                          input-style 
                          mt-2
                      "
                      v-model="freelancer.price"
                      :rules="[() => !!freelancer.price  || 'Rate is required', () => freelancer.price <= 0.15  || 'Rate is too high']"
                      required
                    ></v-text-field>
                    </v-col>
                    </v-row> 

                    <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">  
                        <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Markets</label>                 
                     <v-select
                      :items="markets"
                      v-model="freelancer.markets"
                      item-text="code"
                      item-value="id"
                      outlined
                      multiple
                      return-object
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-2
                        mb-0
                      "
                      single-line
                      height="36"
                      color="rgba(0,0,0,.6)"
                      :rules="[() => !!freelancer.markets || 'Market is required']"
                      required
                    >
                            <template slot="selection" slot-scope="data">
                                                <div class="pl-2 pr-2"> {{ data.item.market_name }}</div>
                                                 <v-img
                                                :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                max-width="25px"
                                                class="font-size-input
                                                  placeholder-lighter
                                                  "
                                            ></v-img>                            
                                          </template>
                                      <template slot="item" slot-scope="data" >
                                                <div class="pr-2 pl-2"> {{ data.item.market_name }} </div>
                                                    <v-img
                                                        :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                        max-width="25px"
                                                        class="  font-size-input
                                                          placeholder-lighter
                                                          mt-2"
                                                    ></v-img>                                
                                              </template>
                    
                    
                     </v-select>
                        </v-col>
                      </v-row>
                   
                 
                  </v-form>


        
    </v-card-text>

    <v-card-actions style="margin:auto;">
          <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "

                @click="createFreelancer()"
              >
                <v-icon size="12" class="pr-4">fa fa-check</v-icon>
                Save
              </v-btn>
    </v-card-actions>
  
  </v-card>

</v-dialog>
  
</template>

<script>

  export default {
    name: 'NewFreelancerCard',
    props: {
      freelancer: {
          default: {},
          required: true
      },
      dialog: {
          type: Boolean,
          default: false
      }
    },
    data: function () {
    return {
      markets: [],
      showFreelancerForm: true,
      websites: [],

    }
  },
  created: async function (){
   
        this.$store.dispatch('getMarkets')
        .then( async (response) => {

              this.markets = response
        }  )
        .catch(() => {
     
          this.$store.commit('showMessage',{ content: "Error while loading markets data...", color: 'warning' })

        })  
  },

  methods: {
      createFreelancer(){
            if(this.$refs.freelancerForm.validate()){
                this.$store.dispatch('createFreelancer',{freelancer: this.freelancer})
                .then( async (response) => {
                    this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                    this.$emit('createdFreelancer',response.data.data)

                })
                .catch((e) => {
                  console.log(e.response)
                    this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
                })    

          }else{
           
                this.$store.commit('showMessage',{ content: "Please fill in the missing fields.", color: 'warning' })

          }
      },
    

  }
  }
</script>
