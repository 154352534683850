<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table      
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
            :sort-desc="[false]"
            :headers="headers"
            :items="freelancers"
            class="table"
            mobile-breakpoint="0"
            :hide-default-footer="true"

          >
           <template v-slot:top>
            <v-toolbar flat height="90">
              <v-row>
                <v-col>
                  <v-text-field
                    
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-4
                    "
                    dense
                    flat
                    filled
                    solo
                    height="36"
                    v-model="search"
                    placeholder="Search"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.enabled="{ item }" >

                    <div class="d-flex align-center" v-if='item.enabled == true'>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-success"
            
              >
                <v-icon
                  size="8"
                  class="text-success"
                >
                  fas fa-check
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Enabled</span>
            </div>

            <div class="d-flex align-center" v-else>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-danger"
            
              >
                <v-icon
                  size="8"
                  class="text-danger"
                >
                  fas fa-times
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Disabled</span>
            </div>

          </template>
              <template v-slot:item.available="{ item }" >

                    <div class="d-flex align-center" v-if='item.available == true'>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-success"
            
              >
                <v-icon
                  size="8"
                  class="text-success"
                >
                  fas fa-check
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Available</span>
            </div>

            <div class="d-flex align-center" v-else>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-danger"
            
              >
                <v-icon
                  size="8"
                  class="text-danger"
                >
                  fas fa-times
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Not Available</span>
            </div>

          </template>
           <template v-slot:item.price="{ item }" >

                €{{ item.price }}
          </template>
       <template v-slot:item.invoices="{ item }" >
                <v-btn  class="" text @click="$router.push({path: '/invoices' , query: {freelancerId: item.id}})"> <v-icon>fa fa-file-invoice</v-icon></v-btn>
          </template>
          <template v-slot:item.markets="{ item }" >
            <v-row>
             <template v-for="market in item.markets">
                <img
                    :key="market.id"
                    v-if="market.flag != null"
                    :src="require('@/assets/img/icons/flags/'+market.flag+'.svg')"
                    style="max-width:35px;"
                    class="mr-2"
                    >
                 </template>
             </v-row>
          </template>
       
  
            <template v-slot:item.actions="{ item }" >
                        
                <v-icon
                class="ml-4"
                small
                @click="openEditFreelancer(item)"
              >
                fa fa-edit
              </v-icon>

            </template>
          
          </v-data-table>     
   </v-card-text>
    </v-card>
        <edit-freelancer-card :dialog=showEditFreelancerDialog v-if="showEditFreelancerDialog"  @closeDialog="showEditFreelancerDialog = false" :freelancer=freelancerData />
  </div>
</template>

<script>
import EditFreelancerCard from "./Components/EditFreelancerCard.vue"

export default {
  name: "FreelancerList",
  components: {
      EditFreelancerCard
  },
  props:{
      freelancers:{
          default: [],
          required: true
      }
  },
  data() {
    return {
      permissions: this.$store.state.permissions,
      search: "",
      headers: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },  
        {
          text: "Name",
          sortable: true,
          value: "name",
          width: "10%"
        },     
        { text: "Email", value: "email" ,sortable: false, width: "12%"},
        { text: "Markets(s)", value: "markets" ,sortable: false , width: "32%"},
        { text: "Cost/Price", value: "price" ,sortable: false, width: "10%"},
        { text: "Enabled/Disabled", value: "enabled" ,sortable: false, width: "12%"},
        { text: "Invoices", value: "invoices" ,sortable: false , width: "5%"},
        { text: "Comment", value: "comment" ,sortable: false , width: "7%"},
        { text: "Available", value: "available" ,sortable: false, width: "6%"},
        { text: "Actions", value: "actions" ,sortable: false , width: "6%"},


      ],
      freelancerData: {},
      showEditFreelancerDialog: false
    };
  },
  created: function (){
    

  },
  methods: {
    openEditFreelancer(item){
        this.freelancerData = item
        this.showEditFreelancerDialog = true
    }
  }
};
</script>
