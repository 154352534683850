<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
       <div class="card-padding pb-6">

           <v-img
              src="@/assets/logo.png"
              class="mr-4"
              style="width:85px !important; float: left !important;"
            >
            </v-img>
           
               <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2 ml-4 pl-4">
                WordFarm
              </h3>
            
         </div>

    <div class="card-padding pb-0">

      <p class="mb-0 text-body">Enter your email and password to login</p>
    </div>
    <div class="card-padding pb-4">
        <v-form
          ref="form"
                  v-model="valid"
                  lazy-validation
        >
      <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
        <v-text-field
                                        
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                                dense
                                single-line
                                height="36"
                                v-model="username"
                                 placeholder="Email"
                                :rules="[() => !!username || 'This field is required']"
                                required
                            />
      <label class="text-xs text-typo font-weight-bolder ms-1">Password</label>
             <v-text-field
                                        
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                                dense
                                single-line
                                height="36"
                                v-model="password"
                                 :rules="[() => !!password || 'This field is required']"
                                 type="password"
                                placeholder="*********"
                                required
                            />

     
      <v-checkbox
        v-model="freelancer"
        color="#141727"
        :ripple="false"
        class="ma-0 checkbox-custom checkbox-thinner"
        
      >
        <template v-slot:label>
          <span class="text-typo text-body-2 ls-0"
            >Freelancer
            </span
          >
        </template>
      </v-checkbox>
       <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="login"
        >Log In</v-btn
      >
        <v-btn 
           class="    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                     py-2
                      px-6
                      me-2
                      mt-7
                      mb-2
                      w-100
                    "
                    color="primary"
        
        @click="forgotPasswordDialog = true" >Forgot Password</v-btn>

     
      </v-form>

    </div>
       <v-dialog
          v-model="forgotPasswordDialog"
          persistent
          max-width="600px"

        >
        <v-card>
            <v-card-title>
                      
             <h5 class="font-weight-bolder text-h5 text-typo mb-0" style="white-space: nowrap !important;">
                      Forgot Password
                    </h5>

            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                
                    <v-col>
                                <label class="text-xs text-typo font-weight-bolder ms-1"
                              >Email</label>
                              <v-text-field
                                  single-line
                                  outlined
                                  color="rgba(0,0,0,.6)"
                                  light
                                  class="
                                      font-size-input
                                      placeholder-lighter
                                      border border-radius-md
                                      mt-2
                                  "
                                v-model="forgot_email"                   
                            >
                            </v-text-field>
                        </v-col>   
                </v-row>
                <v-row>
                      <v-checkbox
                        v-model="forgot_freelancer"
                        label="Freelancer"
                      ></v-checkbox>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="#fff"
                      
                            class="ms-auto btn-info bg-gradient-secondary border-radius-md mt-4 mr-4"
                @click="forgotPasswordDialog = false"
              >
                Close
              </v-btn>
        

                        <v-btn     
                            color="#fff"
                      
                            class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4 ml-2"
                @click="forgotPassword()"
                         >
                Reset Password
                        </v-btn>


            </v-card-actions>
          </v-card>

        </v-dialog>
  </v-card>
  
</template>
<script>
export default {
  name: "sign-up-cover",
  data() {
    return {
      freelancer: false,
      username: '',
      password: '',
      color: 'general',
      valid: '',
      forgotPasswordDialog: false,
      forgot_email: "",
      forgot_user_type: "User",
      forgot_freelancer: ""
    };
  },
  methods: {
    login: function () {
      let username = this.username
      let password = this.password
      if (this.$refs.form.validate()) {
        if(this.freelancer){
              this.$store.dispatch('loginFreelancer', { username, password })
              .then( () => {
                  this.$router.go('/')
              })
              .catch(() => {
                  this.$store.commit('showMessage',{ content: "Incorrect login info", color: 'warning' })
              })

        }else{
           this.$store.dispatch('login', { username, password })
              .then( () => {
                  this.$router.go('/')
              })
              .catch((e) => {
                  this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
              })
          }
      }else {
             this.$store.commit('showMessage',{ content: "Kindly fill in both fields", color: 'warning' })
        }      
    },
    forgotPassword(){

        if(this.forgot_freelancer)
          this.forgot_user_type = 'Freelancer'
       else 
        this.forgot_user_type = 'User'  


        this.$store.dispatch('forgotPassword', { email: this.forgot_email, userType: this.forgot_user_type })
        .then( response => {
          this.forgotPasswordDialog = false
           this.$store.commit('showMessage',{ content:  response.data.msg, color: 'success' })
        })
        .catch(err => {
            this.$store.commit('showMessage',{ content:  err.response.data.msg, color: 'warning' })
        })
    }

  }
  
  
};
</script>
