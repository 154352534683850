<template>
    <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="templates"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          :items-per-page=100
          :sort-by="['id']"
          :sort-desc="[true]"
          mobile-breakpoint="0"
          @contextmenu:row="rightClickEvent"
        >
          <template v-slot:top>
            <v-toolbar flat height="90">
              <v-row>
                <v-col>
                  <v-text-field
                    
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-4
                    "
                    dense
                    flat
                    filled
                    solo
                    height="36"
                    v-model="search"
                    placeholder="Search"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                     <label class="text-xs text-typo font-weight-bolder ms-1 mt-4 "
                          >Show</label>
                    <v-select
                       v-model="filter.enabled" :items="[ {value: 'true',text: 'Enabled Templates'},{ value: 'false' , text: 'Disabled Templates'}]" clearable
                       outlined
                       color="rgba(0,0,0,.6)"
                       class="
                         input-style
                         font-size-input
                         text-light-input
                         placeholder-light
                         border-radius-md
                         select-style
                         mb-0
                       "
                       single-line
                       height="36"
                    ></v-select>

                </v-col>
              </v-row>
            </v-toolbar>
          </template>

         

          <template v-slot:item.markets="{ item }" >
            <v-row>
             <template v-for="market in item.markets">
                <img
                    :key="market.id"
                    v-if="market.flag != null"
                    :src="require('@/assets/img/icons/flags/'+market.flag+'.svg')"
                    style="max-width:35px;"
                    class="mr-2"
                    >
                 </template>
             </v-row>
         </template>
        <template v-slot:item.actions="{ item }" >
                           <v-icon
                small
                @click="viewTemplateDetails(item)"
              >
                fa fa-info-circle 
              </v-icon>
                <v-icon
                class="ml-4"
                small
                v-if='permissions == "Admin" || item.userId == userId'
                @click="openEditTemplateDetails(item)"
              >
                fa fa-edit
              </v-icon>

              <!-- <v-btn color="red" text @click="disableTemplate(item.id)">Disable</v-btn> -->
            </template>
            <template v-slot:item.enabled="{ item }">
                 <v-switch
                       max-width=50px
                        v-if='permissions == "Admin" || item.userId == userId'
                        v-model="item.enabled"
                        @change="disableTemplate(item.id)"
                        class="mt-2 ms-1 pt-0 switch"
                        color="#3a416ff2"
                      ></v-switch>
            </template>
         

    
        </v-data-table>
      </v-card-text>
    </v-card>
        <template-card :dialog=showTemplateDialog v-if="showTemplateDialog"  @closeDialog="showTemplateDialog = false" :template=templateData />
        <edit-template-card :dialog=showEditTemplateDialog v-if="showEditTemplateDialog"  @closeDialog="showEditTemplateDialog = false" :template=templateData />
  </div>
</template>
<script>
import TemplateCard from "./Components/TemplateCard.vue"
import EditTemplateCard from "./Components/EditTemplateCard.vue"

export default {
  name: "template-list",
  components: {
      TemplateCard,
      EditTemplateCard
  },
  props:{
      templates:{
          default: [],
          required: true
      }
  },
  data() {
    return {
      userId:localStorage.getItem('id'),
      permissions: localStorage.getItem('permission'),
      page: 1,
      pageCount: 0,
      search: "",
      filter: {enabled: "true"},
      headers: [
                    {
          text: "ID",
          sortable: true,
          value: "id",
          width: '10%'
        },  
        {
          text: "Template Name",
          sortable: true,
          value: "name",
          width:'15%'
        },     
        { text: "Market(s)",width:'45%', value: "markets" },
        { text: "Created By",width: '15%', value: "user.name" },
        { text: "Actions", width: '10%' ,value: "actions" },
        { text: "Enabled",width: '5%', value: "enabled" , filter: value => {
              if(value.toString() == 'false' && this.filter.enabled == "false")
                return true
              else if (!this.filter.enabled) 
                return true
              else if(this.filter.enabled && value){
                    if(this.filter.enabled == value.toString())
                     return true
                    else
                     return false
              }
            } 
        },
      ],
      showTemplateDialog: false,
      templateData: {},
      showEditTemplateDialog: false
    };
  },
  created: function (){
    

  },
  methods: {
    disableTemplate(templateId){
           this.$store.dispatch('disableTemplateById', {templateId: templateId})
            .then( (response) => {
                 this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
            })
            .catch((e) => {
                  this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
            }) 

    },
    viewTemplateDetails(item){
        this.templateData = item
        this.showTemplateDialog = true
    },
    rightClickEvent(event,item){
        var obj = {}
        obj.event = event
        obj.item = item
        this.$emit('duplicateTemplate',obj)
    },
    openEditTemplateDetails(item){
        this.templateData = item
        console.log(this.templateData)
         this.$store.dispatch('getTemplateDetails', {templateId: this.templateData.id})
       .then( (response) => {
            this.templateData.users = response.data.users
            this.templateData.elements = response.data.data
            this.showEditTemplateDialog = true

       })
       .catch((e) => {
            this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })
       })  
    }
  }
};
</script>
