<template>
 <div>
    <v-container fluid class="py-6">
      <div class="d-sm-flex justify-between">
        <v-btn
          @click="showNewUserDialog = true"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-sm-0 mb-2
          "
          color="#5e72e4"
          small
          >New user</v-btn
        >
        
      </div>

      <v-row>
        <v-col cols="11">
          <v-card class="card-shadow border-radius-xl">
            <user-list :users=users></user-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
            <new-user-card :dialog=showNewUserDialog v-if="showNewUserDialog"  @createdUser="createdUser"  @closeDialog="showNewUserDialog = false" />

  </div>
    
</template>
<script>
import draggable from 'vuedraggable';
import UserList from "./UserList";
import NewUserCard from "./Components/NewUserCard.vue"

export default {
     components: {
            draggable,
            UserList,
            NewUserCard
   },

  data: function () {
    return {
      permissions: localStorage.getItem('permission'),
      markets: [],
      color: 'general',
      search: "",
      userCardDialog: false,
      userData: {},
      users: [],
      elements: [],
      chosenElement: {},
      users: [],
      duplicateuserDetails: {name: "", elements: []},
      showNewUserDialog:false,
    }
  },
  created: function (){

    this.$store.dispatch('getUsers')
        .then( (response) => {
              
              this.users = response.data.data
        }  )
        .catch((e) => {
            this.$store.commit('showMessage',{ content: e.data.msg, color: 'warning' })

        })     

  
  },

  methods: {
    createdUser(user){
        this.users.push(user)
        this.showNewUserDialog = false
        //send event to user list or add to users 
    }
  }
}
</script>
