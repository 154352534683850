<template>
 <div>
    <v-container fluid class="py-6">
      <div class="d-sm-flex justify-between">
        <v-btn
          @click="showNewFreelancerDialog = true"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-sm-0 mb-2
          "
          color="#5e72e4"
          small
          >New Freelancer</v-btn
        >
        
      </div>

      <v-row>
        <v-col cols="11">
          <v-card class="card-shadow border-radius-xl">
            <freelancer-list :freelancers=freelancers></freelancer-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
            <new-freelancer-card :dialog=showNewFreelancerDialog v-if="showNewFreelancerDialog"  @createdFreelancer="createdFreelancer" :freelancer="newFreelancer" @closeDialog="showNewFreelancerDialog = false" />

  </div>
    
</template>
<script>
import draggable from 'vuedraggable';
import FreelancerList from "./FreelancerList";
import NewFreelancerCard from "./Components/NewFreelancerCard.vue"

export default {
     components: {
            draggable,
            FreelancerList,
            NewFreelancerCard
   },

  data: function () {
    return {
      permissions: this.$store.state.permissions,
      markets: [],
      color: 'general',
      search: "",
      freelancers: [],
      newFreelancer: {},
      showNewFreelancerDialog:false,
    }
  },
  created: function (){

    this.$store.dispatch('getFreelancers')
        .then( (response) => {
              
              this.freelancers = response.data.data
        }  )
        .catch((e) => {
 
          this.$store.commit('showMessage',{ content: e.data.msg, color: 'warning' })

        })     

  
  },

  methods: {
    createdFreelancer(freelancer){
        this.freelancers.push(freelancer)
        this.showNewFreelancerDialog = false
        //send event to user list or add to users 
    }
  }
}
</script>
