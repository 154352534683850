<template>
<v-dialog
        width="800px"
        v-model="dialog"
        persistent
    >
    
    <v-card
      class="mx-auto"
    >
  
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title> 
        
        {{ template.name }}
          <v-spacer>
                       </v-spacer>
                      <v-btn  class="mx-2"
                              fab
                              small
                              text
                              @click='$emit("closeDialog")'> <v-icon>
                      fas fa-times font-size-root
                    </v-icon> </v-btn>
    </v-card-title>
<v-divider></v-divider>
<br>
    <v-card-text>
           <v-form>
               <v-list shaped>
               <v-subheader>Template Preview</v-subheader>
               <v-list-item-group
                 color="primary"
               >
                 <v-list-item
                   v-for="(item, i) in templateElements"
                   :key="i"
                 >
      
                   <v-list-item-content>
                            <p :class="'ml-'+item.templateElement.spacing" :style="{'color': item.templateElement.color_code + '!important'}">{{ item.templateElement.element }}</p>
                   </v-list-item-content>
                   <v-row>
                     <v-col cols="4">
                           <v-textarea 
                           label="Headline"
                           rows=1 
                       auto-grow
                       readonly v-model=item.default_headline> </v-textarea>
                     </v-col>
                     <v-col>
                      <v-textarea 
                       label="Instructions"
                       rows=1 
                       auto-grow
                       readonly v-model=item.default_instructions> </v-textarea>
                     </v-col>
                   </v-row>
                 </v-list-item>
               </v-list-item-group>
             </v-list>
           </v-form>
    </v-card-text>

  </v-card>

</v-dialog>
  
</template>

<script>

  export default {
    name: 'TemplateCard',
    props: {
      template: {
          default: null,
          required: true
      },

      showCloseButton: {
        default: true
      },
      dialog: {
          type: Boolean,
          default: false
      }
    },
    data: function () {
    return {
      permissions: this.$store.state.permissions,
      templateElements: []
    }
  },
    created: function (){
           this.$store.dispatch('getTemplateDetails', {templateId: this.template.id})
            .then( (response) => {
                  this.templateElements = response.data.data
                  console.log(this.template)
            }  )
            .catch((e) => {
                   this.$store.commit('showMessage',{ content: e.response.data.msg, color: 'warning' })

            })  
    },
  methods: {
    
    }
  }
</script>
