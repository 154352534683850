<template>
  <v-card class="card-shadow border-radius-xl mt-6" id="basic">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-row>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
          <v-text-field
            v-model="user.email"
            outlined
            color="rgba(0,0,0,.6)"
            light
            placeholder="example@email.com"
            class="
            
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
            "
            single-line
            height="36"
            disabled
          >
          </v-text-field>
        </v-col>
              <v-col v-if="permissions == 'Freelancer'">
                 <label class="text-xs text-typo font-weight-bolder ms-1"
                            >Address</label>
                  <v-textarea
                        rows=1 
                        auto-grow
                        color="rgba(0,0,0,.6)"
                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                      outlined
                      v-model="user.address"
                    ></v-textarea>  
                </v-col>
   
      </v-row>
  
      <v-row>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Your Markets</label
          >
                <v-select
                      :items="markets"
                      v-model="user.markets"
                      item-text="code"
                      item-value="id"
                      outlined
                      multiple
                      return-object
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-2
                        mb-0
                      "
                      single-line
                      height="36"
                      color="rgba(0,0,0,.6)"
                      disabled
                    >
                            <template slot="selection" slot-scope="data">
                                                <div class="pl-2 pr-2"> {{ data.item.market_name }}</div>
                                                 <v-img
                                                :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                max-width="25px"
                                                class="font-size-input
                                                  placeholder-lighter
                                                  "
                                            ></v-img>                            
                                          </template>
                                      <template slot="item" slot-scope="data" >
                                                <div class="pr-2 pl-2"> {{ data.item.market_name }} </div>
                                                    <v-img
                                                        :src="require('@/assets/img/icons/flags/'+data.item.flag+'.svg')"
                                                        max-width="25px"
                                                        class="  font-size-input
                                                          placeholder-lighter
                                                          mt-2"
                                                    ></v-img>                                
                                              </template>
                    
                    
                     </v-select>
        </v-col>
         <v-col v-if="permissions !='Freelancer'" cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >Your Websites</label
          >
                <v-select
                      :items="websites"
                      v-model="user.websites"
                      item-text="domain"
                      item-value="id"
                      outlined
                      disabled
                      multiple
                      return-object
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-2
                        mb-0
                      "
                      single-line
                      height="36"
                      color="rgba(0,0,0,.6)"
                    >
                
                
                    
                     </v-select>
        </v-col>

      </v-row>
      <v-form ref="freelancerForm" v-if="permissions == 'Freelancer'">
         <v-row>
           <v-col cols="6">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                            >Phone Number</label>
                <v-text-field
                          class="
            
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
            "
            single-line
            height="36"
                        outlined
                        v-model="user.phone_no"
                      ></v-text-field>
           </v-col>
     
                    </v-row>
                      <v-row>
                               <v-col>
                           <label class="text-xs text-typo font-weight-bolder ms-1"
                          >VAT Exempt</label>  
                                <v-switch
                                  class="switch"
                                  color="#3a416ff2"
                                  v-model="user.vat_exempt"
                                ></v-switch>
                        </v-col>  
                        <v-col>
                               <label v-if="!user.vat_exempt" class="text-xs text-typo font-weight-bolder ms-1"
                            >Vat NO</label>
                        <v-text-field
                        v-if="!user.vat_exempt"
                                  class="
            
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
            "
            single-line
            height="36"
                               outlined
                               v-model="user.vat_no"
                               :rules="[() => !user.vat_exempt && !!user.vat_no || 'Vat No is required if freelancer is not vat exempt']"
                               required
                                ></v-text-field>
                        </v-col>
                                              
                      </v-row>
                      <v-row>
                        <v-col>
                            <label class="text-xs text-typo font-weight-bolder ms-1"
                            >Payment Method</label>
                       <v-select
                        outlined
                        class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mt-2
                        mb-0
                      "
                       single-line
                                      height="36"
                        :items="['PayPal','Bank Transfer']"
                        v-model="user.payment_method"
                      ></v-select>
                        </v-col>
                        <v-col>
                              <label class="text-xs text-typo font-weight-bolder ms-1"
                            >Payment Details</label>
                      <v-textarea
                         rows=1 
                        auto-grow
                        outlined
                        color="rgba(0,0,0,.6)"
                        class = "font-size-input
                          input-style
                          text-light-input
                          
                          mt-2"
                        v-model="user.payment_instructions"
                      ></v-textarea>
                        </v-col>
                        <v-col>
                           <label class="text-xs text-typo font-weight-bolder ms-1"
                            >Price (€) per word</label>
                     <v-text-field
                      outlined
                                class="
            
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
            "
                      single-line
                      height="36"
                      v-model="user.price"
                      disabled
                    ></v-text-field>  
                        </v-col>
              </v-row>
      </v-form>
     
          <div class="d-sm-flex mt-4" v-if="permissions == 'Freelancer'">
        <v-btn
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-8
            ms-auto
            mt-sm-auto mt-4
          "
          small
          @click="updateFreelancerDetails"
        >
          Update details
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "basic-info",
  data() {
    return {
      user: {},
      markets: [],
      websites: [],
      permissions: this.$store.state.permissions
    };
  },
  created: function (){
      // this.$store.dispatch('getMarkets')
      //   .then( async (response) => {

      //         this.markets = response
      //   }  )
      //   .catch(() => {
     
      //     this.$store.commit('showMessage',{ content: "Error while loading markets data...", color: 'warning' })

      //   }) 
        if(this.permissions != 'Freelancer'){
             this.$store.dispatch('getWebsites')
              .then( async (response) => {

                  this.websites = response.data.data
              }  )
              .catch(() => {
                  this.$store.commit('showMessage',{ content: "Error while loading website list ...", color: 'warning' })
              }) 
        }
  
       this.$store.dispatch('getUser',{userId: localStorage.getItem('id')})
        .then( (response) => {
              
              this.user = response.data.data
              console.log(this.user)
              this.markets = this.user.markets
        }  )
        .catch((e) => {
          console.log(e)
            this.$store.commit('showMessage',{ content: e.data.msg, color: 'warning' })

        })     

  },
  methods: {
        updateFreelancerDetails: function (){
            if(this.$refs.freelancerForm.validate()){
                  this.$store.dispatch('editFreelancer',{freelancer: this.user })
                .then( (response) => {
                    this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
                })
                .catch((e) => {
                    this.$store.commit('showMessage',{ content: eresponse.data.msg, color: 'warning' })
                }) 
            }
        }
  }
};
</script>
