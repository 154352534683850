<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table      
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
            :sort-desc="[false]"
            :headers="headers"
            :items="users"
            class="table"
            :page.sync="page"
            mobile-breakpoint="0"
            :hide-default-footer="true"

          >
           <template v-slot:top>
            <v-toolbar flat height="90">
              <v-row>
                <v-col>
                  <v-text-field
                    
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-4
                    "
                    dense
                    flat
                    filled
                    solo
                    height="36"
                    v-model="search"
                    placeholder="Search"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.enabled="{ item }" >

                    <div class="d-flex align-center" v-if='item.enabled == true'>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-success"
            
              >
                <v-icon
                  size="8"
                  class="text-success"
                >
                  fas fa-check
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Enabled</span>
            </div>

            <div class="d-flex align-center" v-else>
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2 border-danger"
            
              >
                <v-icon
                  size="8"
                  class="text-danger"
                >
                  fas fa-times
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">Disabled</span>
            </div>

          </template>

          <template v-slot:item.markets="{ item }" >
            <v-row>
             <template v-for="market in item.markets">
                <img
                    :key="market.id"
                    v-if="market.flag != null"
                    :src="require('@/assets/img/icons/flags/'+market.flag+'.svg')"
                    style="max-width:35px;"
                    class="mr-2"
                    >
                 </template>
             </v-row>
          </template>
         <template v-slot:item.permissions="{ item }" >

              <template v-if="item.permissions == 'Countrymanager'">
                User
              </template>
                <template v-else>
                {{ item.permissions }}
              </template>
          </template>
  
            <template v-slot:item.actions="{ item }" >
                        
                <v-icon
                class="ml-4"
                small
                @click="openEditUser(item)"
              >
                fa fa-edit
              </v-icon>

              <!-- <v-btn color="red" text @click="disableTemplate(item.id)">Disable</v-btn> -->
            </template>
          
          </v-data-table>     
              <!-- <freelancer-card v-if="viewOrderDialog" :order=orderDialogData /> -->     
   </v-card-text>
    </v-card>
        <edit-user-card :dialog=showEditUserDialog v-if="showEditUserDialog"  @closeDialog="showEditUserDialog = false" :user=userData />
  </div>
</template>

<script>
import EditUserCard from "./Components/EditUserCard.vue"

export default {
  name: "User-list",
  components: {
      EditUserCard
  },
  props:{
      users:{
          default: [],
          required: true
      }
  },
  data() {
    return {
      permissions: localStorage.getItem('permission'),
      page: 1,
      pageCount: 0,
      search: "",
      filter: {enabled: "true"},
      headers: [
                    {
          text: "ID",
          sortable: true,
          value: "id",
          width:"5%"
        },  
        {
          text: "Name",
          sortable: true,
          value: "name",
          width:"10%"

        },     
        { text: "Email", value: "email", width:"20%" },
        { text: "Markets(s)", value: "markets",width:"32.5%" },
        { text: "Enabled/Disabled", value: "enabled" , width:"15%" },
        { text: "Permissions", value: "permissions",width:"10%" },
        { text: "Actions", value: "actions",width:"7.5%" },


      ],
      showTemplateDialog: false,
      userData: {},
      showEditUserDialog: false
    };
  },
  created: function (){
    

  },
  methods: {
    openEditUser(item){
        this.userData = item
        this.showEditUserDialog = true
    }
  }
};
</script>
